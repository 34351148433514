<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Nos solutions</h1>
        </div>
        <div class="menu-head">
            <button @click="scrollMeTo('ecommerce')">E-COMMERCE</button>
            <button @click="scrollMeTo('copacking')">COPACKING</button>
            <button @click="scrollMeTo('logistiqueindus')">LOGISTIQUE INDUSTRIELLE</button>
        </div>
        <div class="d-flex luxe">
            <div class="luxe-img">
                <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/Logistique-luxe.jpg"
                     alt="Produit de luxe"/>
            </div>
            <div class="luxe_txt">
                <h2>PRODUITS DE LUXE</h2>
                <div class="box_content">
                    Depuis de nombreuses années, grâce à nos entrepôts sécurisés et aux solutions de qualité adaptées au
                    marché du luxe, nous prenons en main la logistique de votre circuit de distribution.
                </div>
                <p>
                    Duhamel Logistique dispose d’un site spécialement dédié à la logistique des produits de luxe qui gère
                    actuellement l’exploitation de parfumeurs de niches, des noms prestigieux de bougies et cosmétiques.<br>
                    <br>
                    Nous pouvons ainsi vous apporter notre expérience dans ce domaine pour toutes vos opérations logistiques
                    que sont : la réception, le stockage, la préparation de commande à l’unité, au colis, à la palette, la
                    gestion de stock, l’expédition. Nous sommes également en mesure d’assurer la partie transport. Nous
                    expédions les produits de nos clients aussi bien en France qu’à l’international et par tous les moyens
                    de transport : route, maritime et aérien.<br>
                    <br>
                    Duhamel Logistique dispose d’un conseiller à la sécurité qui s’assure du bon respect de la
                    règlementation des matières dangereuses qui nous sont confiées.
                </p>
            </div>
        </div>
        <div class="d-flex flex-row ecommerce" ref="ecommerce">
            <div class="ecommerce-container">
                <h2>E-COMMERCE</h2>
                <div class="box_content">
                    Nous développons et adaptons les contraintes de la logistique aux besoins de nos clients e-commerce
                    afin d’être toujours plus rapide et efficace. Ces concepts s’appliquent également aux clients
                    hybrides BtoB et BtoC.
                </div>
                <p id="txt_ecommerce">
                    Nous proposons une prestation logistique de l’ e-commerce sur mesure et adaptée aux besoins précis
                    de nos clients. Notre WMS vous permet le suivi de toutes les étapes de votre commande, qui vont de
                    l’envoi de la commande aux particuliers, à la gestion de vos retours si nécessaire.<br>
                    <br>
                    Duhamel Logistique vous assure une gestion complète de la logistique allant de la réception, au
                    suivi du colis, jusqu’au client final. Nous expédions vos commandes via nos partenaires transport en
                    France et à l’international avec un suivi permanent. La réception est détaillée au quotidien, votre
                    stockage est optimisé et nous garantissons un emballage soigné et adapté aux produits préparés.
                </p>
            </div>
        </div>
        <div class="d-flex copacking" ref="copacking">
            <div class="copacking-img">
                <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/copacking.jpg"
                     alt="Copacking"/>
            </div>
            <div class="copacking_txt">
                <h2>COPACKING</h2>
                <div class="box_content">
                    Nous accompagnons et conseillons nos clients sur la mise en œuvre et l’assemblage de packagings, jusqu’à
                    la livraison de ceux-ci aux destinataires finaux.
                </div>
                <p>
                    Le copacking est l’art de regrouper des produits dans un même conditionnement. Chez Duhamel Logistique,
                    cela se traduit notamment par trois principaux processus :<br>
                    <br>
                    Le montage et le remplissage de présentoirs divers et variés pour la cosmétique ou l’édition ;
                    La mise en coffret de parfums et cosmétiques ;
                    La constitution de boxes mensuelles pour les amateurs de vins, cosmétiques, ainsi que pour les enfants.
                    <br><br>
                    Le copacking est souvent rattaché à une offre promotionnelle ou alors à un business-modèle atypique.
                    Notre valeur ajoutée est d’accompagner nos clients dans la mise en œuvre et le pilotage de leurs
                    opérations. Au-delà de la bonne exécution de la tâche de copacking, nous adaptons les délais de
                    production avec une grande flexibilité pour satisfaire les besoins de nos clients, tout en maintenant
                    une communication constante sur l’évolution de notre production.
                    Ainsi, chacun des partenaires travaille en complète sérénité, et c’est ce qui fait la clé du succès de
                    cette activité.
                </p>
            </div>
        </div>
        <div class="d-flex flex-row logistique" ref="logistiqueindus">
            <div class="logistique-container">
                <h2>LOGISTIQUE INDUSTRIELLE</h2>
                <div class="box_content">
                    Réel partenaire de proximité, nous sommes un relai efficient et efficace pour réaliser des
                    prestations logistiques de qualité et sur mesure qui respectent les contraintes liées à la livraison
                    de partenaires professionnels.
                </div>
                <p id="txt_logistique">
                    Duhamel Logistique manage l’entreposage de vos marchandises, de la préparation de vos commandes au
                    colis ou à la palette à destination de vos clients finaux. Nos équipes se chargent du
                    réapprovisionnement quotidien de votre usine, de vos magasins ou de vos clients professionnels
                    finaux grâce à notre flotte de camions ou nos partenaires transports. Nous nous engageons à respecter
                    les demandes clients en matière de délai de livraison mais aussi les demandes et les spécificités
                    présentes dans le cahier des charges.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Solutions",
        methods: {
            scrollMeTo(refName) {
                var element = this.$refs[refName];
                var top = element.offsetTop;

                window.scrollTo(0, top);
            }
        }
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 21%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/25586642770_a6fbfc13f6_o.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 50%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    .menu-head {
        text-align: center;
        padding: 30px;
    }

    button{
        color: #666;
        border-color: #ebebeb;
        background-color: #ebebeb;
        font-size: 14px;
        padding: 14px 20px;
        border-radius: 5px;
        box-shadow: none;
        box-sizing: border-box;
        border: 1px solid transparent;
        margin: 5px;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        text-align: justify;
    }

    .luxe{
        flex-direction: column;
        padding: 100px 15px;
        text-align: left;
    }

    .luxe-img img{
        width: 100%;
        padding-bottom: 20px;
    }

    .box_content{
        font-size: 17px;
        font-weight: 300;
        line-height: 1.65em;
        margin-bottom: 30px;
    }

    .ecommerce{
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/solutions-e-commerce.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 100px 0;
        text-align: center;
    }

    .ecommerce-container{
        padding: 30px 80px;
        background-color: rgba(255,255,255,0.9);
    }

    #txt_ecommerce{
        text-align: center;
    }

    .copacking{
        flex-direction: column;
        padding: 100px 15px;
        text-align: left;
    }

    .copacking-img img{
        width: 100%;
        padding-bottom: 20px;
    }

    .logistique{
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/logistique-Industrielle.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 100px 0;
        text-align: center;
    }

    .logistique-container{
        padding: 30px 80px;
        background-color: rgba(255,255,255,0.9);
    }

    #txt_logistique{
        text-align: center;
    }


    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 16%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        .luxe{
            padding: 100px 100px;
        }

        .ecommerce{
            padding: 100px 100px;
        }

        .copacking{
            padding: 100px 100px;
        }

        .logistique{
            padding: 100px 100px;
        }

    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .luxe{
            flex-direction: row;
            padding: 100px 100px;
        }

        .luxe-img{
            margin-right: 30px;
        }

        .ecommerce{
            padding: 100px 100px;
        }

        .copacking{
            flex-direction: row;
            padding: 100px 100px;
        }

        .copacking-img{
            margin-right: 30px;
        }

        .logistique{
            padding: 100px 100px;
        }

    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        .luxe{
            flex-direction: row;
            padding: 100px 150px;
        }

        .luxe-img{
            margin-right: 30px;
        }

        .ecommerce{
            padding: 100px 150px;
        }

        .copacking{
            flex-direction: row;
            padding: 100px 150px;
        }

        .copacking-img{
            margin-right: 30px;
        }

        .logistique{
            padding: 100px 150px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .luxe{
            flex-direction: row;
            padding: 100px 200px;
        }

        .luxe-img{
            margin-right: 30px;
        }

        .ecommerce{
            padding: 100px 200px;
        }

        .copacking{
            flex-direction: row;
            padding: 100px 200px;
        }

        .copacking-img{
            margin-right: 30px;
        }

        .logistique{
            padding: 100px 200px;
        }

    }
</style>