<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Nos Valeurs</h1>
        </div>
        <div class="container_valeurs">
            <div class="satisfaction">
                <div class="satisfaction-icon"></div>
                <h2 class="satisfaction_title">SATISFACTION CLIENT</h2>
                <p class="txt_orange">
                    Assurer un service logistique de qualité et mettre à disposition une équipe compétente, capable de
                    répondre aux besoins et aux demandes de nos clients.
                </p>
                <p>
                    Nous adaptons en temps réel notre logistique aux besoins présents et futurs de nos clients pour
                    construire une relation durable. Des outils physiques et informatiques performants permettent à nos
                    clients de piloter leur logistique en toute sérénité.
                </p>
            </div>
            <div class="equipe">
                <div class="equipe-icon"></div>
                <h2 class="equipe_title">ESPRIT D'ÉQUIPE</h2>
                <p class="txt_orange">
                    Favoriser l’implication collective de nos collaborateurs autour de projets communs.
                </p>
                <p>
                    Nous faisons coexister bien-être et performance au sein de l’ensemble de nos équipes et les fédérons
                    autour de projets communs.
                </p>
            </div>
            <div class="perennite">
                <div class="perennite-icon"></div>
                <h2 class="perennite_title">PÉRENNITÉ DE L'ENTREPRISE</h2>
                <p class="txt_orange">
                    Assurer la croissance et le développement de l’entreprise grâce à des équipes motivées et
                    audacieuses.
                </p>
                <p>
                    Au quotidien, nous transformons l’efficacité individuelle et collective en performance durable.
                </p>
            </div>
            <div class="innovation">
                <div class="innovation-icon"></div>
                <h2 class="innovation_title">INNOVATION</h2>
                <p class="txt_orange">
                    Développer de nouveaux process pour anticiper les besoins spécifiques de nos clients et partenaires.
                </p>
                <p>
                    Nous plaçons l’innovation comme un indicateur clé de notre croissance. Notre créativité se nourrit
                    des compétences, des cultures et des parcours des hommes et des femmes qui font notre entreprise.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Valeurs"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 21%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/IMG_9556-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 2%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    .container_valeurs {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 10%;
    }

    .satisfaction {
        width: 100%;
        margin-bottom: 35px;
    }

    .satisfaction-icon {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        border: 1px solid #EA670C;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/like.png");
        background-position: center;
        background-size: 50%;
        background-repeat: no-repeat;
        margin: 0 auto 15px;
    }

    .equipe {
        width: 100%;
        margin-bottom: 35px;
    }

    .equipe-icon {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        border: 1px solid #EA670C;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/team.png");
        background-position: center;
        background-size: 50%;
        background-repeat: no-repeat;
        margin: 0 auto 15px;
    }

    .perennite {
        width: 100%;
        margin-bottom: 35px;
    }

    .perennite-icon {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        border: 1px solid #EA670C;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/growth.png");
        background-position: center;
        background-size: 50%;
        background-repeat: no-repeat;
        margin: 0 auto 15px;
    }

    .innovation {
        width: 100%;
        margin-bottom: 35px;
    }

    .innovation-icon {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        border: 1px solid #EA670C;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/test.png");
        background-position: center;
        background-size: 50%;
        background-repeat: no-repeat;
        margin: 0 auto 15px;
    }

    .txt_orange {
        color: #EA670C;
        font-weight: 700;
    }

    p {
        font-size: 14px;
    }

    h2 {
        font-size: 14px;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 16%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        .container_valeurs {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 10%;
        }

        .satisfaction {
            width: 40%;
        }

        .equipe {
            width: 40%;
        }

        .perennite {
            width: 40%;
        }

        .innovation {
            width: 40%;
        }

    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

    }

</style>