<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Notre Vision</h1>
        </div>
        <div class="vision_head">
            <p>
                Nous plaçons l’humain au cœur de notre organisation : <strong> d’équipe, reconnaissance, valorisation,
                communication</strong> et <strong>entraide</strong> sont nos <strong>maîtres mots.</strong><br>
                La taille et la flexibilité de notre structure apportent à nos salariés sécurité,
                <strong>polyvalence</strong> et <strong>autonomie.</strong><br>
                <br>
                Nos <strong>170 collaborateurs</strong> ont une responsabilité particulière, celle d’accompagner nos
                clients et
                ainsi, faciliter la gestion des flux de leurs produits partout dans le monde.<br>
                <br>
                A travers la diversité de leur métier, <strong>les femmes et les hommes de Duhamel Logistique
                transforment
                les défis du quotidien en une aventure humaine.</strong><br>
                <br>
                Entreprise engagée, nous agissons en faveur de <strong>l’égalité des chances</strong>, notamment celles
                données <strong>aux
                jeunes d’accéder à une première expérience.</strong> Depuis quelques mois, nous avons rejoint le réseau
                "100
                chances 100 emplois" qui permet à des jeunes éloignés de l’emploi d’être parrainés par des
                entreprises qui les aident dans leur conquête du marché du travail.
            </p>
        </div>

      <div class="container_atout">
        <p>           </p>
      </div>

        <div class="d-flex container_vision">
            <div class="bamboo">
                <h2 class="titrebamboo">LA RICHESSE DE NOS MÉTIERS</h2>
                <p>Le développement de notre entreprise et la richesse des métiers de la logistique
                    nous permettent de
                    proposer à nos clients toute une diversité de métiers. Pour cela, nous investissons dans le
                    développement des compétences et la mobilité interne, qui donnent à chacun de réelles perspectives
                    d’évolution.</p>
                <h2 class="titrebamboo">NOS ENGAGEMENTS</h2>
                <p >Nous souhaitons donner à chaque collaborateur la possibilité d’évoluer et de
                    grandir. Au quotidien,
                    nous valorisons chacun de nos collaborateurs et 70% d’entre eux sont formés afin de garantir
                    l’employabilité</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Vision"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 21%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/IMG_9556-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 2%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .container_atout {
      width: 100%;
      background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/10/20200918-DL-inauguration-site-Evreux-©sylvainbachelot_101-scaled.jpg");
      height: 500px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: 20%;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    p {
        font-size: 14px;
    }

    h2 {
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
    }

    .vision_head {
        padding: 0 80px;
    }

    .container_vision {
        width: 100%;
        background-repeat: no-repeat;
        padding: 80px 0;
    }

    .bamboo {
      text-align: left;
    }

    .titrebamboo {
      text-align: left;
      font-size: 18px;
      font-weight: 900;
      color: #4d4e4f;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    .engagements {
        padding: 80px;
        background-color: rgba(255, 255, 255, 0.9);
    }

    .box-content {
        font-size: 17px;
        font-weight: 300;
        line-height: 1.65em;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 16%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        .container_vision {
            flex-direction: row;
            padding: 80px 0;
            justify-content: space-around;
        }

        .richesse {
            padding: 80px;
            background-color: rgba(255, 255, 255, 0.9);
            width: 35%;
        }

        .engagements {
            padding: 80px;
            background-color: rgba(255, 255, 255, 0.9);
            width: 35%;
        }

    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        .container_vision {
            padding: 80px 300px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .container_vision {
            padding: 80px 390px;
        }

    }

</style>