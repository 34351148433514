<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">On Parle de Nous</h1>
        </div>
        <div class="articles">
            <div v-for="article in articles" :key="article.id" class="d-flex flex-row">
                <div class="articles-content d-flex flex-row">
                    <div class="articles-img">
                        <img :src="article.better_featured_image.source_url">
                    </div>
                    <div class="articles_content_txt d-flex flex-column">
                        <h2>{{article.title.rendered}}</h2>
                        <div>{{ new Date(article.date) | dateFormat('dddd DD MMMM YYYY', dateFormatConfig) }}</div>
                        <p v-html="article.excerpt.rendered"></p>
                        <router-link class="is-tab nav-item news_button " :to="'/on-parle-de-nous/' + article.id"><p>
                            Lire la suite</p></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "OnParleDeNous",
        data() {
            return {
                articles: [],
                dateFormatConfig: {
                    dayOfWeekNames: [
                        'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi',
                        'Vendredi', 'Samedi'
                    ],
                    monthNames: [
                        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'
                    ],
                }
            }
        },

        created: function () {
            this.$http.get('wp/v2/posts?categories=8&per_page=100').then(response => {
                for (let article in response.data) {
                    this.articles.push(response.data[article]);
                }
            }, error => {
                alert(error)
            });
        }

    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 21%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/IMG_9476-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 78%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    .articles-content {
        border-bottom: 2px solid #EA670C;
        margin-bottom: 20px;
        padding: 15px;
    }

    .articles-img {
        width: 100%;
    }

    .articles-img img {
        width: 100%;
    }

    p {
        font-size: 14px;
        margin-bottom: 0;
    }

    h2 {
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
        margin-bottom: 10px;
        text-align: center;
    }

    h3 {
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
        margin-bottom: 10px;
        text-align: center;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 16%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .articles-img {
            width: 60%;
        }

    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        .articles-content {
            padding: 20px;
            text-align: left;
        }

        .articles-img {
            width: 40%;
            margin-right: 20px;
        }

        h2 {
            text-align: left;
        }

        h3 {
            text-align: left;
        }

    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .articles-content {
            padding: 30px;
            margin-right: 10%;
            margin-left: 10%;
        }

        .articles-img {
            width: 30%;
            margin-right: 30px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        .articles-content {
            padding: 40px;
        }

        .articles-img {
            width: 20%;
            margin-right: 40px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .articles-content {
            padding: 50px;
        }

        .articles-img {
            width: 15%;
            margin-right: 50px;
        }

    }

</style>