<template>
    <nav class="header">
        <div class="menu-button" @click="toggle">
            <span>AFFICHER LE MENU</span>
            <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/menu.png">
        </div>
        <Drawer @close="toggle" align="up" :closeable="true">
            <div v-if="open">
                <div class="menu">
                    <div class="menu-column">
                        <span class="title-menu">A PROPOS</span>
                        <a class="is-tab nav-item" href="https://www.dimotrans-group.com/" target="_blank">Le groupe</a>
                        <hr>
                        <router-link class="is-tab nav-item" to="/l-entreprise">L'entreprise</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/nos-atouts">Nos atouts</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/nos-valeurs">Nos valeurs</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/galerie-photos">Galerie photos</router-link>
                        <hr>
                    </div>
                    <div class="menu-column">
                        <span class="title-menu">NOS SOLUTIONS</span>
                        <router-link class="is-tab nav-item" to="/solutions">Produits de luxe</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/solutions">E-commerce
                        </router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/solutions">Copacking</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/solutions">Logistique industrielle</router-link>
                        <hr>
                    </div>
                    <div class="menu-column">
                        <span class="title-menu">NOS SERVICES</span>
                        <a class="is-tab nav-item" href="https://www.duhamel-transports.fr/" target="_blank">Duhamel Transit International</a>
                        <hr>
                      <a class="is-tab nav-item" href="https://www.n-ine.fr/" target="_blank">Service informatique</a>
                       <hr>
                    </div>
                    <div class="menu-column">
                        <span class="title-menu">ESPACE RH</span>
                        <router-link class="is-tab nav-item" to="/notre-vision">Notre vision</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/nos-metiers">Nos metiers</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/nous-rejoindre">Nous rejoindre</router-link>
                        <hr>
                    </div>
                    <div class="menu-column">
                        <span class="title-menu">ACTUALITÉS</span>
                        <router-link class="is-tab nav-item" to="/newsletters">Newsletters</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/on-parle-de-nous">On Parle de Nous</router-link>
                        <hr>
                    </div>
                    <div class="menu-column">
                        <a class="title-menu" href="https://egonetv10.duhamel-logistique.fr/">Accès Ego.Net</a>

                    </div>
                </div>
            </div>
        </Drawer>
        <div class="div_logo">
            <router-link to="/" class="logo">
                <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/Duhamel-Logistique-blanc.png" id="vwp-logo"
                     alt="Logo Duhamel Logistique"/>
            </router-link>
            <div class="devis">
                <router-link to="/devis-en-ligne" class="button-devis">Demande de devis</router-link>
            </div>
        </div>

    </nav>
</template>

<script>
    import Drawer from "vue-simple-drawer"

    export default {
        name: 'TheHeader',
        data() {
            return {
                open: false,
            }
        },
        components: {
            Drawer
        },
        props: {
            msg: String
        },
        methods: {
            toggle() {
                this.open = !this.open
            },
        },
        watch: {
            $route() {
                this.open = false
            }
        }
    }
</script>

<style scoped>
    .header {
        background-color: #182630;
        position: fixed;
        top: 0;
        height: 80px;
        z-index: 100;
        width: 100%;
    }

    .div_logo {
        text-align: center;
        line-height: 80px;
    }

    a.button-devis:hover {
        color: #191919 !important;
    }

    a {
        color: #ffffff !important;
    }

    a:hover {
        color: #EA670C !important;
        text-decoration: none;
    }

    .menu-button {
        position: inherit;
        left: 20px;
        top: 25px;
        color: white;
    }

    .menu-button span {
        display: none;
    }

    .menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .menu-column {
        display: flex;
        flex-direction: column;
        width: 60%;
        margin-bottom: 30px;
        text-align: center;
    }

    .title-menu {
        color: #ffffff;
        justify-content: center;
        border: 1px solid #ffffff;
        border-radius: 4px;
        padding: 10px 15px;
        margin-bottom: 10px;
        width: 54vw;
    }

    .nav-item {
        padding-top: 3px;
        padding-bottom: 3px;
        width: 100%;
    }

    hr {
        width: 35px;
        border-bottom: 1px solid #ffffff;
        margin-top: 0;
    }

    #vwp-logo {
        width: 20%;
    }

    .button-devis {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        height: 40px;
        width: 100%;
        line-height: 40px;
        background-color: #EA670C;
        margin-top: -50px;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    @media (min-width: 576px) {
        #vwp-logo {
            width: 13%;
        }

        .button-devis {
            font-size: 16px;
            margin-top: -45px;
        }

        .title-menu {
            width: 57vw;
        }
    }

    @media (min-width: 768px) {
        .header {
            height: 90px;
        }

        .div_logo {
            line-height: 90px;
        }

        #vwp-logo {
            width: 15%;
        }

        .menu-button {
            top: 28px;
        }

        .menu-column {
            margin-top: 30px;
            width: 40%;
        }

        .title-menu {
            width: 38vw;
        }

        .devis {
            margin-left: 77vw;
            margin-top: -89px;
        }

        .button-devis {
            font-size: 16px;
            width: 90%;
            border-radius: 10px;
            margin-top: 0;
        }

    }

    @media (min-width: 992px) {
        .menu-button {
            top: 15px;
        }

        .header {
            height: 100px;
        }

        .div_logo {
            line-height: 100px;
        }

        .menu-button span {
            display: block;
            width: 80px;
            font-size: 12px;
        }

        .menu-column {
            width: 20%;
        }

        .title-menu {
            width: 14vw;
            margin-left: 3vw;
        }

        #vwp-logo {
            width: 13%;
        }

        .devis {
            margin-left: 77vw;
            margin-top: -100px;
        }

        .button-devis {
            font-size: 18px;
        }
    }

    @media (min-width: 1200px) {
        .menu-button {
            top: 10px;
        }

        .menu-button span {
            font-size: 1rem;
        }

        .title-menu {
            width: 14vw;
            margin-left: 3vw;
        }

        #vwp-logo {
            width: 10%;
        }

    }

    @media (min-width: 1600px) {
        .menu-button {
            top: 10px;
        }

        .menu-button span {
            font-size: 1rem;
        }

        #vwp-logo {
            width: 9%;
        }

    }
</style>
