<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Nos Atouts</h1>
        </div>
      <div class="container_atout">
        <p>           </p>
      </div>

        <div class="container_atouttxt">
            <div class="points_forts">
                <h2 class="points_forts_title">NOS POINTS FORTS</h2>
                <ul>
                    <li>Des équipes réactives, souples et proches du client</li>
                    <li>Des prestations de qualité sur mesure</li>
                    <li>Une structure à taille humaine</li>
                    <li>Des tarifs adaptés aux besoins</li>
                    <li>Un accompagnement et des conseils logistiques</li>
                </ul>
            </div>
            <div class="engagements">
                <h2 class="engagements_title">NOS ENGAGEMENTS</h2>
                <ul>
                    <li>Faire de votre service logistique un facteur clé de votre croissance</li>
                    <li>Optimiser la stratégie logistique de nos clients</li>
                    <li>Promouvoir la philosophie de nos clients au travers de la qualité de nos prestations</li>
                    <li>Développer des concepts sur mesure</li>
                    <li>Assurer l'excellence opérationnelle et la qualité de service</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Atouts"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 21%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/IMG_9556-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 2%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    .container_atout {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/IMG_9993-scaled.jpg");
      height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
    }

    .container_atouttxt {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
    }

    .points_forts {
        color: black;
        padding: 35px 80px;
    }

    .points_forts_title {
        text-align: left;
        font-size: 18px;
        font-weight: 900;
        color: black;
        margin-bottom: 20px;
    }

    .engagements {
        color: black;
        padding: 35px 80px;
    }

    .engagements_title {
        text-align: left;
        font-size: 18px;
        font-weight: 900;
        color: black;
        margin-bottom: 20px;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 16%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .container_atout {
            width: 100%;
            padding: 0;
            margin: 0;
        }

      .container_atouttxt {
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        .container_atout {
            width: 100%;
            padding: 0;
            margin: 0;
            flex-direction: row;
        }

      .container_atouttxt {
        width: 100%;
        padding: 0;
        margin: 0;
        flex-direction: row;
      }

        .points_forts {
            width: 50%;
        }

        .engagements {
            width: 50%;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        .points_forts {
            width: 40%;
            margin-left: 7%;
            margin-right: 5%;
        }

        .engagements {
            width: 40%;
        }
    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

    }

</style>