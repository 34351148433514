<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Mentions légales</h1>
        </div>
        <div class="mention_container">
            <h2>DROITS D’AUTEURS, TEXTES, PHOTOS, LIENS – COPYRIGHT ©</h2>
            <p>Le site http://www.duhamel-logistique.fr/ est la propriété de la société Duhamel Logistique. L’ensemble
                du site relève de la législation française et internationale sur le droit d’auteur et la propriété
                intellectuelle. Toute représentation, utilisation ou reproduction (dans sa forme ou son contenu) du site
                http://www.duhamel-logistique.fr/ est strictement interdite sans autorisation écrite préalable de la
                société Duhamel Logistique. Elle sera en tout état de cause soumise à l’obligation de faire apparaître
                la mention claire et lisible de l’adresse du site : http://www.duhamel-logistique.fr/. L’établissement
                de tout lien hypertexte pointant vers ce site est soumis au même régime. Toutes images, textes et
                éléments graphiques utilisés sans le consentement de la société Duhamel Logistique pourra faire l’objet
                de poursuites légales.</p>
            <br>
            <h2>AVERTISSEMENT</h2>
            <p>Les données mises en ligne sur le site Internet ont pour objectif de présenter la société Duhamel
                Logistique. Leur exactitude est périodiquement contrôlée et leur mise à jour assurée régulièrement. Des
                erreurs ou omissions indépendantes de la volonté de la société Duhamel Logistique peuvent toutefois se
                glisser dans les pages de son site. En tout état de cause, la responsabilité de la société Duhamel
                Logistique ne saurait être retenue en cas de préjudice direct ou indirect (notamment tout préjudice
                financier, commercial, perte de programme ou de donnée) résultant de l’usage de son site et de
                l’utilisation des données et informations qui y sont mises en ligne. La société Duhamel Logistique
                décline également toute responsabilité à l’égard de tout dommage lié à l’utilisation des liens
                hypertextes mise en ligne sur son site et à la consultation des sites auxquels ils renvoient. Il est
                expressément rappelé que la société Duhamel Logistique n’a aucun contrôle ni aucune responsabilité sur
                le contenu de ces sites. Il incombe donc à chaque internaute de prendre les précautions nécessaires afin
                de s’assurer que le site sélectionné n’est pas infesté de virus ou autre parasite de nature
                destructive.</p>
            <br>
            <h2>INFORMATIONS NOMINATIVES</h2>
            <p>Ce site Web, qui comporte des informations nominatives concernant ses partenaires, a fait l’objet d’une
                déclaration auprès de la CNIL. Les informations recueillies par le biais de nos formulaires de contact
                et de newsletter n’ont d’autre but que de renseigner et de pouvoir répondre aux interrogations des
                internautes. Aucune information personnelle n’est cédée à des tiers. Conformément à la loi «
                informatique et libertés » du 6 janvier 1978, les internautes bénéficient d’un droit d’accès et de
                rectification aux informations qui les concernent. Si vous souhaitez exercer ce droit et obtenir
                communication ou suppression des informations vous concernant (vous pouvez, pour des motifs légitimes,
                vous opposer au traitement des données vous concernant), veuillez nous contacter via les coordonnées
                ci-dessous.</p>
            <br>
            <h2>COOKIE</h2>
            <p>Nous vous informons que lors de vos visites sur le site, un cookie peut s’installer automatiquement
                sur votre logiciel de navigation (un cookie est un bloc de données qui ne permet pas de l’identifier mais
                qui sert à enregistrer des informations relatives à la navigation de celui-ci sur le site). En général,
                ce cookie contient, un numéro d’identification ou un code qui nous permet de vous reconnaître lorsque
                vous retournez sur notre site. La mise en place de ces systèmes nous permet simplement la récupération
                de données statistiques afin d’améliorer notre site et de mieux répondre à vos besoins. Les données
                concernées ne sont en aucun cas cédées à des tiers. Nous vous rappelons que votre navigateur possède des
                fonctionnalités qui vous permettent de vous opposer à l’enregistrement de cookies, d’être prévenu avant
                d’accepter les cookies, ou de les effacer.
                Pour plus d’information sur les cookies ainsi que sur les différentes prescriptions décrites plus haut,
                nous vous recommandons de visiter le site Internet de la CNIL : http://www.cnil.fr</p>
            <br>
            <h2>CREDITS</h2>
            <ul>
                <li>Raison sociale : DUHAMEL LOGISTIQUE</li>
                <li>Adresse du siège social : Voie du futur Parc d’Affaires des portes – 27100 VAL DE REUIL</li>
                <li>Numéro de téléphone : 02.32.59.09.18</li>
                <li>RCS Evreux N° 432.182.343 – N° SIRET : 432.182.343.00025</li>
                <li>Capital Social. 247 600 €</li>
                <li>Directeur de publication : Hugues DUHAMEL</li>
                <li>Responsable de la rédaction : Hugues DUHAMEL</li>
                <li>TVA intracommunautaire : FR 33.432.182.343</li>
                <li>Code APE : 8292Z</li>
                <li>Adresse e-mail : contact@duhamel-logistique.fr</li>
                <li>Crédit photo : Sylvain BACHELOR, Nathan ROUSSEAU et Pauline LEROY</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Mentions"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 15%;
        margin-bottom: 50px;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/201511-duhamel-logistique_2-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 66%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 16px;
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    a {
        color: #191919 !important;
        margin-right: 13px;
        margin-left: 13px;
        margin-bottom: 10px;
    }

    a:hover {
        color: #EA670C !important;
        text-decoration: none;
    }

    h2{
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    p {
        text-align: justify;
    }

    li{
        text-align: left;
    }

    .mention_container{
        margin: 15px;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .title {
            font-size: 18px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 20px;
        }

        .mention_container{
            margin: 30px;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .title {
            font-size: 24px;
        }

        .mention_container{
            margin: 60px 150px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 28px;
        }

        .mention_container{
            margin: 60px 250px;
        }
    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .title {
            font-size: 34px;
        }

        .mention_container{
            margin: 60px 500px;
        }
    }
</style>