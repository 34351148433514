<template>
    <div class="Collection-post d-flex flex-row">
        <div v-for="media in medias" :key="media.id" class="d-flex flex-row">
            <div class="media-content d-flex flex-column">
                <img class="collection-img" :src="media.source_url">
                <span class="media_txt" v-html="media.title.rendered"></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WpCollection",
        data() {
            return {
                medias: []
            }
        },
        created: function () {
            this.$http.get('wp/v2/media?categories=6&per_page=50').then(response => {
                for (let media in response.data) {
                    this.medias.push(response.data[media]);
                }
            }, error => {
                alert(error)
            });
        }
    }
</script>

<style scoped>
    .Collection-post {
        flex-wrap: wrap;
        justify-content: center;
    }

    .media-content {
        margin-bottom: 15px;
        width: 40vw;
        justify-content: center;
        margin-right: 15px;
    }

    .media-content span {
        height: 30px;
        font-size: 16px;
        line-height: 30px;
    }

    .media-content img:hover + span {
        visibility: visible;
    }

    .collection-img {
        width: 100%;
    }

    .media_txt {
        background-color: #191919;
        font-size: 24px;
        color: #ffffff;

    }

    @media (min-width: 576px) {
        .media-content {
            margin-bottom: 20px;
        }
    }

    @media (min-width: 768px) {
        .media-content {
            margin-bottom: 30px;
            margin-right: 30px;
        }
    }

    @media (min-width: 992px) {
        .Collection-post {
            justify-content: center;
        }

        .media-content span {
            visibility: hidden;
            display: block;
        }

        .media-content img:hover + span {
            visibility: visible;
        }

        .media-content {
            margin-bottom: 40px;
            width: 30vw;
            margin-right: 40px;
        }

        .collection-img {
            width: 100%;
        }
    }


</style>