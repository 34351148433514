import Vue from 'vue';
import App from './App.vue';
import VueResouce from 'vue-resource';
import router from "./router";
import VueNumber from "vue-number-animation";
import VueSimpleAlert from "vue-simple-alert";
import VueMoment from 'vue-moment';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueAnalytics from 'vue-analytics';


Vue.config.productionTip = false;
Vue.use(VueResouce);
Vue.use(VueNumber);
Vue.use(VueMoment);
Vue.use(VueSimpleAlert);
Vue.use(VueFilterDateFormat);
Vue.http.options.root = 'https://api.duhamel-logistique.fr/wp-json';
Vue.use(VueAnalytics, {
    id: 'UA-78709538-1',
    router
})


new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
