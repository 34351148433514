<template>
    <div class="job d-flex flex-row">
        <div v-for="job in jobs" :key="job.id" class="d-flex flex-row">
            <div class="job-content d-flex flex-column">
                <img class="job-img" :src="job.better_featured_image.source_url">
                <router-link class="is-tab nav-item job_title " :to="'/details/' + job.id"><h2>
                    {{job.title.rendered}}</h2></router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WpJob",
        data() {
            return {
                jobs: []
            }
        },
        created: function () {
            this.$http.get('wp/v2/posts?categories=3&per_page=100').then(response => {
                for (let job in response.data) {
                    this.jobs.push(response.data[job]);
                }
            }, error => {
                alert(error)
            });
        }
    }
</script>

<style scoped>
    .job {
        width: 100%;
        flex-wrap: wrap;
        margin-right: 15px;
        margin-left: 15px;
        justify-content: center;
    }

    .job-content {
        width: 42vw;
        vertical-align: middle;
        margin-right: 15px;
    }

    .job-img {
        width: 100%;
    }

    .job_title {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: -16vh;
        color: #F6F6F6;
        height: 47px;
        margin-left: 4px;
        margin-right: 4px;
        padding-top: 8px;
        background-color: rgba(171, 175, 173, 0.5);
    }

    h2 {
        font-size: 10px;
    }

    .job_title:hover {
        background-color: rgba(234, 103, 12, 0.5);
        text-decoration: none;
        margin: 0;
        height: 300px;
        margin-top: -150px;
        top: -150px;
    }

    @media (min-width: 576px) {
        .job_title {
            height: 47px;
            margin-left: 4px;
            margin-right: 4px;
            padding-top: 8px;
        }

    }

    @media (min-width: 768px) {
        h2 {
            font-size: 12px;
        }

    }

    @media (min-width: 992px) {
        .job-content {
            width: 30vw;
        }

        h2 {
            font-size: 16px;
        }

        .job_title {
            top: -15vh;
        }

    }

    @media (min-width: 1200px) {
        .job-content {
            width: 23vw;
        }

        h2 {
            font-size: 18px;
        }

        .job_title {
            top: -15vh;
            height: 67px;
        }
    }

    @media (min-width: 1600px) {
        .job-content {
            width: 23vw;
        }

        h2 {
            font-size: 20px;
        }

        .job_title {
            top: -21vh;
            height: 87px;
        }
    }


</style>