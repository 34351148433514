<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Nous rejoindre</h1>
        </div>
        <div class="title_txt">
            <p>Venez découvrir la variété et la richesse de nos métiers et faites connaissance avec les femmes et les
                hommes qui les exercent avec passion, au service de nos clients.
                Exprimez et révélez pleinement vos talents dans les domaines de la logistique.
                En nous rejoignant, vous découvrirez toute une diversité de métiers au cœur de la prestation logistique.
                Accompagner les clients, innover, adapter la gestion des flux, autant de domaines et de parcours
                passionnants.</p>
        </div>
        <div class="collection d-flex flex-row">
            <wp-offer></wp-offer>
        </div>
        <div class="postuler">
            <router-link class="is-tab nav-item job_title primary-button post-button" :to="'/candidature-spontanee'"><span>Candidature Spontanée</span>
            </router-link>
        </div>
    </div>
</template>

<script>
    import WpOffer from "../../WpOffer";

    export default {
        name: "Join",
        components: {
            WpOffer,
        },
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 15%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/2018-08-Duhamel-logistique-inauguration-site-9_57-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 60%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 16px;
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    .title_txt {
        margin: 15px;
    }

    p {
        font-size: 8px;
    }

    .postuler {
        height: 50px;
        padding-top: 10px;
    }

    .post-button {
        font-size: 10px;
        font-weight: 500;
        border-radius: 8px;
        background-color: #EA670C;
        padding: 10px;
        color: white;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .title {
            font-size: 18px;
        }

        .title_txt {
            margin: 20px;
        }

        p {
            font-size: 10px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 20px;
        }

        .title_txt {
            margin: 30px;
        }

        p {
            font-size: 12px;
        }

        .post-button {
            font-size: 12px;
            padding: 12px;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .title {
            font-size: 24px;
        }

        .title_txt {
            margin: 40px;
        }

        p {
            font-size: 16px;
        }

        .post-button {
            font-size: 16px;
            padding: 16px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 28px;
        }

        .post-button {
            font-size: 16px;
            font-weight: 500;
            border-radius: 8px;
            padding: 16px;
        }
    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .title {
            font-size: 34px;
        }

    }
</style>