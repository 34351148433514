<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Nous trouver</h1>
        </div>
        <iframe src="https://www.google.com/maps/d/embed?mid=1rwdU6YxDe-MoyKbbXmnSpEpu8aQ" width="100%" height="450"
                frameborder="0" style="border:0" allowfullscreen></iframe>
        <div class="site_blanc">
            <h2>ACCÈS AU SITE 1</h2>
            <div class="adress d-flex">
                <p class="adress-text">64 rue de 11 novembre <br>
                    27690 LERY <br>
                    Tel. 06.45.96.32.18</p>
                <div class="accès d-flex">
                    <h3>Depuis Paris</h3>
                    <p>Sur l’autoroute A13, utiliser la sortie N°19 (Incarville), puis suivre la direction Val de Reuil.
                        Après le 2ème feu, en face du centre commercial AUCHAN, continuez tout droit en direction de la
                        gare jusqu’au Stop. Prendre à gauche en direction de Pont de L’Arche puis après avoir dépassé le
                        centre de détention ‘Les Vignettes’, prener la première à gauche. Après 3 kilomètres direction
                        Lery tourner à droite au stop, puis encore à droite après la Boulangerie Pâtisserie du village.
                        Après 200 mètres, le site n°1 se trouve sur votre droite.</p>
                    <h3>Depuis Le Havre / Rouen</h3>
                    <p>Sur l’autoroute A13, utiliser la sortie N°20 (Pont de l’Arche), puis prener à droite en direction
                        de Pont de l’Arche. Traverser 4 ronds point puis prendre la D77E sur la gauche. Au rond-point
                        suivant prendre à droite direction Val de Reuil. Prendre la première à gauche juste avant le
                        centre de détention « les Vignettes ». Après 3 kilomètres direction Lery, tourner à droite au
                        stop, puis encore à droite après la Boulangerie Pâtisserie du village. Après 200 mètres, le site
                        n°1 se trouve sur votre droite.</p>

                </div>
            </div>
        </div>
        <div class="site_gris">
            <h2>ACCÈS AU SITE 2</h2>
            <div class="adress d-flex">
                <p class="adress-text">
                    ZA les vignettes <br>
                    27690 LERY <br>
                    Tel. 02.32.63.32.20 <br>
                    Fax 02.32.59.17.77 <br>
                    <br>
                    Coordonnées GPS : N 49.288527, E 1.204510
                </p>
                <div class="accès d-flex">
                    <h3>Depuis Paris</h3>
                    <p>Sur l’autoroute A13, utiliser la sortie N°19 (Incarville), puis suivez la direction Val de Reuil.
                        Après le 2ème feu, en face du centre commercial ATAC, continuez tout droit en direction de la
                        gare jusqu’au Stop. Prenez à gauche en direction de Pont de L’Arche puis après avoir dépassé le
                        centre de détention ‘Les Vignettes’, prenez la première à gauche.</p>
                    <h3>Depuis Le Havre / Rouen</h3>
                    <p>Sur l’autoroute A13, utiliser la sortie N°20 (Pont de l’Arche), puis prenez à droite en direction
                        de Pont de l’Arche. Traverser 4 ronds point puis prendre la D77E sur la gauche. Au rond point
                        suivant prendre à droite direction Val de Reuil. Après 2 kilomètres le site n°2 se trouve sur
                        votre droite, après les silos à béton.</p>

                </div>
            </div>
        </div>
        <div class="site_blanc">
            <h2>ACCÈS AU SITE 4</h2>
            <div class="adress d-flex">
                <p class="adress-text">
                    Parc Activité du VAUVRAY <br>
                    2 Voie de l’Ormet <br>
                    27100 VAL de REUIL <br>
                    Tel. 02.32.63.75.97 <br>
                    Fax 02.32.59.04.65 <br>
                    <br>
                    Coordonnées GPS : N 49.2404841, E 1.2308178
                </p>
                <div class="accès d-flex">
                    <h3>Depuis Paris</h3>
                    <p>Sur l’autoroute A13, prendre la sortie n°19. Suivre Parc Activité du VAUVRAY</p>
                    <h3>Depuis Le Havre / Rouen</h3>
                    <p>Sur l’autoroute A13, prendre la sortie n°19. Suivre Parc Activité du VAUVRAY.</p>

                </div>
            </div>
        </div>
        <div class="site_gris">
            <h2>ACCÈS AU SITE 5 ET SIÈGE</h2>
            <div class="adress d-flex">
                <p class="adress-text">
                    Parc Affaires des Portes <br>
                    Voie du futur <br>
                    27100 VAL de REUIL <br>
                    Tel. 02.32.59.09.18 <br>
                    Fax 02.32.59.55.76 <br>
                    <br>
                    Coordonnées GPS : N 49.2524852, E 1.1806564
                </p>
                <div class="accès d-flex">
                    <h3>Depuis Paris</h3>
                    <p>Sur l’autoroute A13, prendre la sortie n°19 Val De Reuil et continuer en direction du Parc
                        d’Affaires des Portes. Au rond point, prendre la 3ième sortie, Voie des Clouets et continuer sur
                        600m. Prendre à droite Voie du Futur et continuer sur 100m. L’entrée se situe sur votre
                        gauche.</p>
                    <h3>Depuis Le Havre / Rouen</h3>
                    <p>Sur l’autoroute A13, prendre la sortie Val De Reuil A154 qui comporte un péage. Prendre la sortie
                        n°1. Au rond point, prendre à gauche et continuer 900m sur la n154. Au rond point, prendre la
                        3ième sortie Voie des Clouets et continuer sur 600m. Prendre à droite Voie du Futur et continuer
                        sur 100m. L’entrée se situe sur votre gauche.</p>

                </div>
            </div>
        </div>
        <div class="site_blanc">
            <h2>ACCÈS AU SITE 6</h2>
            <div class="adress d-flex">
                <p class="adress-text">
                    857 rue de la lande <br>
                    Mesnil Raoul <br>
                    76520 BOOS <br>
                    Tel. 02.35.79.01.79 <br>
                    Fax 02.35.79.95.42 <br>
                </p>
                <div class="accès d-flex">
                    <h3>Depuis Paris</h3>
                    <p>Sur l’autoroute A13, prendre la sortie n°21. Tourner à gauche au rond point, traverser le rond
                        point suivant puis sortir à droite (suivre la direction « Aéroport »). Rond point suivant à
                        droite. Rond point suivant à gauche. Traverser le rond point pour rester sur la D13. Rester sur
                        la D13 jusqu’a l’intersection avec la D6014. Traversez la, après quelques centaines de mètres,
                        les entrepots se trouvent sur votre droite.</p>
                    <h3>Depuis Le Havre / Rouen</h3>
                    <p>Sur l’autoroute A13, prendre la sortie A154. Rejoindre la D7 puis prendre la D6015. Sortir en
                        direction de la D13.</p>

                </div>
            </div>
        </div>
        <div class="site_gris">
            <h2>ACCÈS AU SITE 8</h2>
            <div class="adress d-flex">
                <p class="adress-text">
                    ZAC du long buisson Rue Maryse Bastié <br>
                    27930 le Vieil Evreux <br>
                    Tél: 02 32 23 54 67 <br>
                    Fax: 02 32 28 96 24 <br>
                </p>
                <div class="accès d-flex">
                    <h3>Depuis Paris</h3>
                    <p>Sur l’autoroute A13, prendre la sortie n°15 Evreux et rejoindre la D113 puis la N13 pendant une
                        dizaine de kilomètres. Au rond-point, prendre la 2ème sortie sur Route Nationale (N13) et
                        continuer sur 2,6 kilomètres. Sortir et rejoindre N13. Continuer sur 700 mètres puis tourner à
                        droite sur N13 et continuer sur 450 mètres. Au rond-point, prendre la 3ème sortie sur Rue Maryse
                        Bastié puis au rond-point suivant prendre la 2ème sortie. L’entrée se situe sur votre
                        droite.</p>
                    <h3>Depuis Le Havre / Rouen</h3>
                    <p>Sur l’autoroute A13, prendre la A154 en direction d’Evreux. Continuer sur 7 kilomètres puis
                        prendre la N154 pendant 20 kilomètres. Au rond-point, prendre la 3ème sortie sur Rue Maryse
                        Bastié puis au rond-point suivant prendre la 2ème sortie. L’entrée se situe sur votre
                        droite.</p>

                </div>
            </div>
        </div>
        <div class="site_blanc">
            <h2>ACCÈS AU SITE 9</h2>
            <div class="adress d-flex">
                <p class="adress-text">
                    ZA les vignettes <br>
                    27690 LERY <br>
                    Tel. 02.32.63.75.94 <br>
                </p>
                <div class="accès d-flex">
                    <h3>Depuis Paris</h3>
                    <p>Sur l’autoroute A13, utiliser la sortie N°19 (Incarville), puis suivre la direction Val de Reuil.
                        Après le 2ème feu, en face du centre commercial AUCHAN, continuer tout droit en direction de la
                        gare jusqu’au Stop. Prenez à gauche en direction de Pont de L’Arche puis après avoir dépassé le
                        centre de détention ‘Les Vignettes’, prendre la première à gauche.</p>
                    <h3>Depuis Le Havre / Rouen</h3>
                    <p>Sur l’autoroute A13, utiliser la sortie N°20 (Pont de l’Arche), puis prendre à droite en
                        direction de Pont de l’Arche. Traverser 4 ronds point puis prendre la D77E sur la gauche. Au
                        rond-point suivant prendre à droite direction Val de Reuil. Après 2 kilomètres le site n°9 se
                        trouve sur votre droite, avant les silos à béton.</p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FindUs"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 15%;
        margin-bottom: 50px;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/22-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 40%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 16px;
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    iframe {
        margin-bottom: 30px;
    }

    h2 {
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
    }

    h3 {
        color: #555555;
        font-size: 14px;
        font-weight: bold;
    }

    p {
        font-size: 14px;
        text-align: justify;
    }

    .adress {
        flex-direction: column;
        text-align: left;
        margin: 15px;
    }

    .adress-text {
        width: 30%;
        color: #555555;
        font-size: 14px;
    }

    .accès {
        flex-direction: column;
    }

    .site_blanc {
        width: 100%;
        padding: 30px 0px;
    }

    .site_gris {
        width: 100%;
        background-color: #F6F6F6;
        padding: 30px 0px;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .title {
            font-size: 18px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 20px;
        }

        .adress {
            flex-direction: row;
            margin: 30px;
        }

        .adress-text {
            width: 25%;
            margin-right: 0px;
            margin-left: 11%;
        }

        .accès {
            width: 50%;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .title {
            font-size: 24px;
        }

        .adress {
            margin: 30px 100px;
        }

    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 28px;
        }

        .adress {
            margin: 30px 200px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .title {
            font-size: 34px;
        }

        .adress {
            margin: 30px 300px;
        }
    }
</style>