<template>
    <div class="main">
        <div class="head" v-bind:style="{ backgroundImage: 'url(' + job.better_featured_image.source_url + ')' }">
        </div>
        <div class="back">
            <router-link class="is-tab nav-item job_title back_button" to="/nous-rejoindre"><span>Retour</span>
            </router-link>
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">{{job.title.rendered}}</h1>
        </div>
        <div class="postuler">
            <router-link class="is-tab nav-item job_title primary-button post-button" :to="'/recrutement/' + job.id"><span>Postuler</span>
            </router-link>
        </div>
        <div class="container d-flex flex-row">
            <p v-html="job.content.rendered"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SingleOffer",
        data() {
            return {
                job: {}
            }
        },
        created: function () {
            this.$http.get('wp/v2/posts/' + this.$route.params.id).then(response => {
                this.job = response.data
            }, error => {
                alert(error)
            });
        }
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 15%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 70%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 16px;
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    p {
        font-size: 8px;
    }

    .container {
        background-color: #F6F6F6;
        margin: 0;
        padding: 0;
        max-width: 100%;
        text-align: justify;
    }

    .back {
        position: absolute;
        font-size: 10px;
        float: left;
        margin-left: 5px;
        margin-top: 10px;
    }

    .back_button {
        background: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/arrow-back.png") no-repeat left center;
        padding-left: 13px;
        color: #EA670C;
    }

    .postuler {
        height: 50px;
        padding-top: 10px;
        background-color: #F6F6F6;
    }

    .post-button {
        font-size: 10px;
        font-weight: 500;
        border-radius: 8px;
        background-color: #EA670C;
        padding: 10px;
        color: white;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .title {
            font-size: 18px;
        }

        p {
            font-size: 10px;
        }

        .back {
            position: absolute;
            font-size: 10px;
            float: left;
            margin-left: 5px;
            margin-top: 10px;
        }

        .back_button {
            padding-left: 17px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 20px;
        }

        p {
            font-size: 12px;
        }

        .back {
            position: absolute;
            font-size: 12px;
            float: left;
            margin-left: 15px;
            margin-top: 20px;
        }

        .back_button {
            padding-left: 20px;
        }

        .postuler {
            position: absolute;
            height: 0px;
            margin-left: 80%;
            margin-top: 3.5%;
        }

        .post-button {
            font-size: 12px;
            padding: 12px;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .title {
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }

        .back {
            position: absolute;
            font-size: 16px;
            float: left;
            margin-left: 25px;
            margin-top: 30px;
        }

        .postuler {
            margin-left: 80%;
            margin-top: 3%;
        }

        .post-button {
            font-size: 16px;
            padding: 16px;
        }

    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 28px;
        }

        p {
            font-size: 18px;
        }

        .back {
            position: absolute;
            font-size: 16px;
            float: left;
            margin-left: 25px;
            margin-top: 30px;
        }

        .postuler {
            margin-left: 75%;
            margin-top: 2.5%;
        }

        .post-button {
            font-size: 16px;
            font-weight: 500;
            border-radius: 8px;
            padding: 16px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .title {
            font-size: 34px;
        }

        .postuler {
            margin-left: 70%;
            margin-top: 2%;
        }
    }

</style>